export const AFFoundations = {
  foundations: [
    {
      "FoundationName": "Ascendus",
      "link": "https://www.ascendus.org/apply/",
    },
    {
      "FoundationName": "CareerSource South Florida's COVID-19 Layoff Aversion Fund",
      "link": "https://iapps.careersourcesfl.com/LayoffAversion_BeaconCouncil/",
    },
        {
      "FoundationName": "Career Source South Florida Operation Impact",
      "link": "https://iapps.careersourcesfl.com/operationimpact/",
    },
        {
      "FoundationName": "City of North Miami Business Grants",
      "link": "https://www.northmiamifl.gov/811/Business-Grants",
    },
    {
      "FoundationName": "Enterprise Florida: Virtual Business Matchmaking Grants",
      "link": "https://www.enterpriseflorida.com/services/export-from-florida/tradegrants/virtual-business-matchmaking-grants/",
    },
    {
      "FoundationName": "Florida DEO Rebuild Florida Business Loan Fund",
      "link": "https://deosera.force.com/RebuildFloridaBusinessLoanFund/s/loan-programs?program=florida-resiliency-loan",
    },
    {
      "FoundationName": "Florida DEO Microfinance Guarantee Program",
      "link": "https://covid19.floridajobs.org",
    },
    {
      "FoundationName": "FPL Main Street Recovery Credit Program",
      "link": "https://www.fpl.com/business/coronavirus/main-street-recovery.html",
    },
    {
      "FoundationName": "FAMU Federal Credit Union Black Business Loan Program",
      "link": "https://famufcu.com/black-business-loans/",
    },
    {
      "FoundationName": "Health Foundation of South Florida",
      "link": "https://hfsf.org/funding-opportunities/",
    },
    {
      "FoundationName": "Key Biscayne Community Foundation Emergency Hardship Grant Application",
      "link": "https://www.kbcf.org/what-we-do/grants-scholarships/emergency-hardship-grant/emergency-hardship-grant-application/",
    },
    {
      "FoundationName": "Kiva Loans for US Entrepreneurs",
      "link": "https://www.kiva.org/borrow/pre-application",
    },
    {
      "FoundationName": "Knight Foundation Funding Opportunities",
      "link": "https://knightfoundation.org/programs/",
    },
    {
      "FoundationName": "Miami Bayside Foundation (MBF) Loan Resource Center",
      "link": "https://miamibaysidefoundation.org/loans/resources/",
    },
    {
      "FoundationName": "Miami-Dade County Cultural Affairs Grants",
      "link": "https://miamidadearts.smapply.io/",
    },
    {
      "FoundationName": "Miami-Dade County Dept of Cultural Affairs Community Grant",
      "link": "https://miamidadearts.org/community-grants-cg-program",
    },
    {
      "FoundationName": "Miami-Dade County District 5 Retail Relief Program",
      "link": "https://www.miamidade.gov/global/government/commission/district05/retail-relief-program.page#:~:text=This%20program%20provides%20financial%20relief,mortgage%20payments%20of%20commercial%20spaces",
    },
    {
      "FoundationName": "The Miami Foundation COVID-19 Community Recovery Resources",
      "link": "https://miamifoundation.org/recoveryresources",
    },
    {
      "FoundationName": "United Way of Miami Dade COVID-19 Resource Center",
      "link": "https://unitedwaymiami.org/coronavirusresources/",
    },
    {
      "FoundationName": "North Miami CRA",
      "link": "https://www.northmiamicra.org/covid19-resources-links",
    },
    {
      "FoundationName": "Ooolite Artist Relief",
      "link": "https://oolitearts.org/relieffund/",
    },
    {
      "FoundationName": "Opa-Locka CDC COVID Recovery + Relief",
      "link": "https://www.olcdc.org/covidrelief",
    },
    {
      "FoundationName": "Partners for Self-Employment",
      "link": "http://partnersforselfemployment.org/programs/",
    },
    {
      "FoundationName": "RISE Miami-Dade Small Business Loan",
      "link": "https://risemiamidade.com/",
    },
    {
      "FoundationName": "South Florida Regional Planning Council (SFRPC) Revolving Loan Fund",
      "link":"https://sfregionalcouncil.org/portfolio-items/revolving-loan-fund-rlf/",
    },
    
    {
      "FoundationName": "The Batchelor Foundation",
      "link": "https://www.grantinterface.com/Home/Logon?urlkey=tbfinc",
    },
    {
      "FoundationName": "The NBRHD Restaurant Development Program",
      "link": "https://reeftechnology.com/nbrhd-restaurant-development-program/",
    },
    {
      "FoundationName": "U.S. Department of Commerce NOAA FY 2021 Small Business Innovation Research Phase II Grant",
      "link": "https://www.grants.gov/web/grants/view-opportunity.html?oppId=330562",
    },
    {
      "FoundationName": "Verizon Small Business Recovery Fund",
      "link": "https://www.lisc.org/covid-19/covid-resources/opportunities/",
    },
  ],
}

export default AFFoundations
